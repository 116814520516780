"use client";

import {
  Sidebar,
  SidebarButton,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
  SidebarTrigger
} from "@/components/ui/sidebar";
import {
  CreditCard,
  LayoutDashboard,
  LayoutList,
  LogOut,
  Moon,
  PanelLeft,
  Puzzle,
  Settings,
  Sun
} from "lucide-react";
import { useTheme } from "next-themes";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

interface NavItem {
  title: string;
  url: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const primaryItems: NavItem[] = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: LayoutDashboard
  },
  {
    title: "Borrowers",
    url: "/borrowers",
    icon: CreditCard
  },
  {
    title: "Actions",
    url: "/actions",
    icon: LayoutList
  },
  {
    title: "Integrations",
    url: "/integrations",
    icon: Puzzle
  }
];

const secondaryItems = [
  {
    title: "Theme",
    icon: Moon
  },
  {
    title: "Settings",
    url: "/settings",
    icon: Settings
  }
];

export function AppSidebar() {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  const pathname = usePathname();

  // Set mounted to true after the component has mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader className="my-2">
        <SidebarMenuItem>
          <SidebarMenuButton
            asChild
            className="no-underline hover:bg-transparent hover:dark:bg-transparent"
          >
            <Link href="/">
              <span className="h-8 w-8 shrink-0 rounded-lg bg-primary-500 text-white">
                <svg
                  id="b"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24.49 21.21"
                  className="h-full w-full fill-current"
                >
                  <path
                    d="M12.25,0L0,21.21h24.49L12.25,0ZM4.42,18.98l4.77-8.26,4.77,8.26H4.42ZM10.85,8.24l1.52-2.62,7.71,13.36h-3.03l-6.2-10.74Z"
                    transform="scale(0.70) translate(5, 5)"
                  />
                </svg>
              </span>
              <span className="text-xl font-semibold">Anelli</span>
            </Link>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarHeader>
      <SidebarSeparator />

      <SidebarContent>
        <SidebarGroup>
          {primaryItems.map((item, key) => {
            const isActive = pathname.startsWith(item.url);

            return (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton
                  asChild
                  tooltip={item.title}
                  isActive={isActive}
                >
                  <a href={item.url}>
                    <item.icon className="ml-1.5 !h-5 !w-5" />
                    <span>{item.title}</span>
                  </a>
                </SidebarMenuButton>
              </SidebarMenuItem>
            );
          })}
        </SidebarGroup>
      </SidebarContent>

      <SidebarFooter className="mb-2">
        <SidebarGroupLabel>Settings</SidebarGroupLabel>

        {/* Collapse Sidebar */}
        <SidebarMenuItem>
          <SidebarMenuButton asChild tooltip="Open Sidebar">
            <SidebarTrigger>
              <PanelLeft className="ml-1.5" />
              <span>Collapse Sidebar</span>
            </SidebarTrigger>
          </SidebarMenuButton>
        </SidebarMenuItem>

        {/* Toggle Dark Mode */}
        <SidebarMenuItem>
          <SidebarMenuButton asChild tooltip="Toggle Theme">
            <SidebarButton onClick={toggleTheme}>
              {mounted ? (
                theme === "light" ? (
                  <Sun className="ml-1.5" />
                ) : (
                  <Moon className="ml-1.5" />
                )
              ) : (
                <Moon className="ml-1.5" />
              )}
              <span>Toggle Theme</span>
            </SidebarButton>
          </SidebarMenuButton>
        </SidebarMenuItem>

        {/* Account Settings */}
        <SidebarMenuItem>
          <SidebarMenuButton asChild tooltip="Account">
            <a href="/account">
              <Settings className="ml-1.5" />
              <span>Account</span>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>

        {/* Logout */}
        <SidebarSeparator />
        <SidebarMenuItem>
          <SidebarMenuButton asChild tooltip="Log Out">
            <a href="/account/logout">
              <LogOut className="ml-1.5" />
              <span>Log Out</span>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarFooter>
    </Sidebar>
  );
}
